<template>
   <v-app id="inspire">
      <v-container fluid fill-height>
         <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
               <v-card class="elevation-12">
                  <v-toolbar dark color="#0066cc">
                     <v-toolbar-title>Login utente</v-toolbar-title>
                  </v-toolbar>
                  <v-card-subtitle v-if="errorMessage" class="red--text">{{errorMessage}}</v-card-subtitle>
                  <v-card-text>
                     <v-form ref="formLogin" v-model="formLoginFieldsValid" lazy-validation>
                        <v-text-field
                           v-model="user"
                           prepend-icon="mdi-account-outline"
                           name="login"
                           label="Utente"
                           type="text"
                           @keydown.enter="onLogin"
                           @keydown="errorMessage=''"
                        ></v-text-field>
                        <v-text-field
                           v-model="password"
                           id="password"
                           prepend-icon="mdi-lock-outline"
                           name="password"
                           label="Password"
                           :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                           :rules="[rules.required]"
                           :type="showPassword ? 'text' : 'password'"
                           @click:append="showPassword = !showPassword"
                           @keydown.enter="onLogin"
                           @keydown="errorMessage=''"
                        ></v-text-field>
                        <v-text-field
                           v-model="token"
                           prepend-icon="mdi-account-key-outline"
                           name="token"
                           label="Token"
                           type="text"
                           @keydown.enter="onLogin"
                           @keydown="errorMessage=''"
                        ></v-text-field>                        
                     </v-form>
                  </v-card-text>
                  <v-card-actions>
                     <v-spacer></v-spacer>
                     <v-btn @click="onLogin">Login</v-btn>
                  </v-card-actions>
               </v-card>
            </v-flex>
         </v-layout>
      </v-container>
   </v-app>
</template>

<script>
   import selfapi from '@/services/selfapi.js'

   export default {
      name: 'Login',
      data () {
         return {
            showPassword: false,
            user: '',
            password:'',
            token: '',
            rules: {
               required: value => !!value || 'Il campo è richiesto'
            },
            formLoginFieldsValid: true,
            errorMessage: ''
         }
      },
      methods: {
         async onLogin() {
            const valid = this.$refs.formLogin.validate()
            if (valid) {
               const info = await selfapi.userLogin(this.user, this.password, this.token)
               console.log(info)
               if (info.status === 'ko') {
                  this.errorMessage = info.message
               } else {
                  await this.$store.dispatch('utenti/setInfo', info)
                  let redirect = this.$route.query.returnUrl || '/'
                  this.$router.replace(redirect)              
               }
            }
         }
      }
   }
</script>

<style></style>
